<template>
  <div>
    <b-overlay :show="userLoading">
      <b-card>
        <b-row v-if="user">
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <div class="d-flex justify-content-start flex-wrap">
              <b-avatar
                v-if="!isMobile"
                class="mr-1"
                variant="light-primary"
                size="104px"
                rounded
              >
                <UserIcon />
              </b-avatar>
              <div class="d-flex flex-column">
                <div class="mb-1 d-flex flex-wrap">
                  <div>
                    <h4 class="mb-0">
                      {{ user.firstName }} {{ user.lastName }}
                    </h4>
                    <span class="card-text">{{ user.email }}</span>
                  </div>
                  <tags-list
                    :tags="user.tags"
                    class="m-50"
                    :show-hidden-by-toggle="false"
                  />
                </div>
                <div class="mt-auto">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon m-50"
                    @click="onLoginAs"
                  >
                    Login as user
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            class="p-1"
          >
            <b-row>
              <b-col>
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country</span>
              </b-col>
              <b-col>
                {{ user.country.name }}
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Kyc country</span>
              </b-col>
              <b-col>
                <span v-if="user.kyc_country">{{ user.kyc_country.name }}</span>
                <span v-else>-</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <feather-icon
                  icon="UserPlusIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Registered</span>
              </b-col>
              <b-col>
                {{ formatDateTime(user.createdAt) }}
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <feather-icon
                  icon="KeyIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Registered with affiliate key(NEW AFFILIATE)</span>
              </b-col>
              <b-col>
                {{ user.partner ? `${user.partner.aff_custom_key} | ${user.partner.user.email}` : 'User wasn\'t register with affiliate link' }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Verified at</span>
              </b-col>
              <b-col>
                {{ formatDateTime(user.kyc_at) }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Comment</span>
              </b-col>
              <b-col>
                {{ user.comment }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <hr>
    <accounts-table-external-gql
      :columns="accountColumns"
      :per-page-max="5"
      title="Accounts"
    />
    <hr>
    <orders-table-external-gql
      class="mt-1"
      :columns="orderColumns"
      :per-page-max="5"
      title="Orders"
    />
    <hr>
    <user-withdrawal-table-external-gql
      :user-id="userId"
      class="mt-1"
    />

  </div>
</template>
<script>
import {
  BAvatar, BCard, BCol, BRow, BOverlay, BButton,
} from 'bootstrap-vue'
import { UserIcon } from 'vue-feather-icons'
import { get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import AccountsTableExternalGql from '@/components/entity/account-gql/AccountsTableExternalGql.vue'
import OrdersTableExternalGql from '@/components/entity/order/OrdersTableExternalGql.vue'
import generateGraphQLQuery from '@/plugins/gqlGenerator'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime } from '@/plugins/formaters'
import UserAPI from '@/api/user'
import UserWithdrawalTableExternalGql from '@/components/entity/withdrawalGql/UserWithdrawalTableExternalGql.vue'

export default {
  components: {
    UserWithdrawalTableExternalGql,
    BButton,
    BOverlay,
    BAvatar,
    TagsList,
    BCol,
    BRow,
    BCard,
    UserIcon,
    OrdersTableExternalGql,
    AccountsTableExternalGql,
  },
  data() {
    return {
      user: null,
      userLoading: false,
    }
  },
  computed: {
    ...get('app', ['isMobile']),
    userId() {
      return this.$route.params.id
    },
    accountColumns() {
      return [
        'show_details',
        {
          name: 'accountNumber', label: 'Account number', filterable: 'eq', visible: false,
        },
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.userId,
        },
        {
          name: 'account_type_name',
          label: 'Account type',
        },
        {
          name: 'account_subtype_name',
          label: 'Account phase',
        },
        {
          name: 'account_subtype_name',
          label: 'Phase',
        },
        {
          name: 'rules',
          sortable: true,
          label: 'ID|DD|TD|FW|W|PS',
        },
        {
          name: 'specialOption',
          label: 'Special options',
        },
        {
          name: 'tags',
          label: 'Tags',
        },
        {
          name: 'account_id',
          label: 'Account number',
        },
        {
          name: 'parent_id',
          label: 'Parent number',
        },
        {
          name: 'order_id',
          label: 'Order number',
        },
        {
          name: 'deposit',
          label: 'Deposit',
        },
        {
          name: 'balance',
          label: 'Balance',
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'start_datetime',
          label: 'Start date',
          sortable: true,
        },
        {
          name: 'end_datetime',
          label: 'End date',
          sortable: true,
        },
        {
          name: 'close_datetime',
          label: 'Close date',
          sortable: true,
        },
        {
          name: 'state', label: 'State',
        },
      ]
    },
    orderColumns() {
      return [
        {
          name: 'orderNumber', label: 'Order number', filterable: 'eq', visible: false,
        },
        {
          name: 'order_number', label: 'Order number',
        },
        {
          name: 'tags',
          label: 'Tag',
        },
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.userId,
        },
        {
          name: 'price', label: 'Price', sortable: true,
        },
        {
          name: 'product',
          label: 'Product',
          sortable: false,
        },
        {
          name: 'createdAt', label: 'Created at', sortable: true,
        },
        {
          name: 'payment_datetime', label: 'Payment at', sortable: true,
        },
        {
          name: 'state', label: 'State',
        },
      ]
    },
  },
  created() {
    const query = [{
      user: {
        args: [
          { name: 'id', value: this.userId },
        ],
        fields: [
          'id',
          'email',
          'firstName',
          'lastName',
          'createdAt',
          'kyc_at',
          'comment',
          {
            country: {
              fields: ['name'],
            },
          },
          {
            kyc_country: {
              fields: ['name'],
            },
          },
          {
            partner: {
              fields: [
                'aff_custom_key',
                {
                  user: {
                    fields: ['email'],
                  },
                },
              ],
            },
          },
          { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
        ],
      },
    }]
    this.userLoading = true
    this.$apollo.query({
      query: generateGraphQLQuery(query, 'getuser'),
      fetchPolicy: 'network-only',
    }).then(result => {
      this.user = result.data.user
    }).finally(() => {
      this.userLoading = false
    })
  },
  methods: {
    formatDateTime,
    onLoginAs() {
      UserAPI.postLoginAs(this.user.id)
        .then(response => {
          Swal.fire({
            title: 'Login as user',
            html: `<p>Here is user login link: (link is valid for 2 minutes)</p><a target="_blank" href="${response}">${response}</a>`,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>
