<template>
  <gql-table
    ref="withdrawalExtTable"
    :query="query"
    :fields="columns"
    :per-page-max="5"
    title="Payouts"
    query-type="withdrawals"
    sort-by="createdAt"
    :sort-desc="true"
  >
    <template #cell(user)="data">
      <b-row>
        <b-col>
          <b-link :to="{ name: 'user-detail-ext', params: { id: data.item.user.id } }">
            <span :class="['completed', 'approved by support'].includes(data.item.state) ? '' : 'text-danger'">{{ data.item.user.firstName }} {{ data.item.user.lastName }}</span>
            ({{ data.item.user.country.name }})
            <br>
            <small>{{ data.item.user.email }}</small>
          </b-link>
        </b-col>
        <b-col>
          <tags-list
            :tags="data.item.user.tags"
            :extras-tags="getUserExtraTags(data.item.user)"
            extras-variant="light-success"
          />
        </b-col>
      </b-row>
    </template>

    <template #cell(account)="data">
      <b-row>
        <b-col>
          <b-link :to="{ name: 'account-detail-ext', params: { id: data.item.account.account_id } }">
            {{ data.item.account.account_id }} ({{ accountTypes[data.item.account.account_type_name] }} - {{ data.item.account.server.platform.id }})
          </b-link>
          <risk-score-badge
            v-if="data.item.account.risk_score"
            class="mx-1"
            :score-results="data.item.account.risk_score_results"
            :score="data.item.account.risk_score"
          />
        </b-col>
        <b-col>
          <tags-list
            :tags="data.item.account.tags"
            :extras-tags="getAccountExtraTags(data.item.account)"
          />
          <span v-if="data.item.account.profit_split === 90">UNFAZED</span>
        </b-col>
      </b-row>
    </template>

    <template #cell(deposit)="data">
      {{ formatCurrency(data.item.account.deposit, data.item.account.currency.label) }}
    </template>

    <template #cell(current_balance)="data">
      <span v-if="!data.item.completed">{{ formatCurrency(data.item.account.balance - data.item.account.deposit, data.item.account.currency.label) }}</span>
      <span v-else>Withdrawal completed</span>
    </template>

    <template #cell(captured_balance)="data">
      {{ formatCurrency(data.item.requested_amount, data.item.account.currency.label) }}
    </template>

    <template #cell(amount)="data">
      {{ formatCurrency(profitShareAmount(data.item), data.item.account.currency.label) }}
      <b-badge
        v-if="data.item.account.withdrawal_count === 0 || (data.item.account.withdrawal_count === 1 && data.item.withdrawal_count === 1)"
        variant="light-primary"
      >
        {{ getRootAccount(data.item.account, data.item.requested_amount, data.item.profit_share, data.item.account.currency.label) }}
      </b-badge>
    </template>

    <template #cell(profit_share)="data">
      {{ data.item.profit_share }}%
    </template>

    <template #cell(withdrawal_datetime)="data">
      {{ formatDateTime(data.item.withdrawal_datetime) }}
    </template>

    <template #cell(state)="data">
      <b-badge
        :variant="`light-${withdrawalStateVariants[data.item.state]}`"
      >
        {{ data.item.state }}
      </b-badge>
    </template>

    <template #cell(createdAt)="data">
      {{ formatDateTime(data.item.createdAt) }}
    </template>

    <template #cell(confirmed_at)="data">
      {{ formatDateTime(data.item.confirmed_at) }}
    </template>
  </gql-table>
</template>
<script>
import {
  BBadge,
  BCol, BLink, BRow, VBTooltip,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'

import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import { ACCOUNTS_SHORT } from '@/enums/accounts'

export default {
  components: {
    BBadge,
    BRow,
    TagsList,
    BCol,
    RiskScoreBadge,
    BLink,
    GqlTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userId: String,
  },
  data() {
    return {
      withdrawalStateVariants: STATE_VARIANTS,
      accountTypes: ACCOUNTS_SHORT,
      query: [
        'id',
        'amount',
        'comment',
        'completed',
        'confirmed_at',
        'createdAt',
        'profit_share',
        'state',
        'withdrawal_count',
        'requested_amount',
        'createdAt',
        'withdrawal_datetime',
        'platform',
        'comment',
        {
          user: {
            fields: [
              'firstName',
              'lastName',
              'id',
              'email',
              'risk_score',
              'risk_score_results',
              'kyc_at',
              'purchase_disabled',
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
              { country: { fields: ['id', 'name'] } },
            ],
          },
        },
        {
          account: {
            fields: [
              'id',
              'account_id',
              'account_type_name',
              'balance',
              'deposit',
              'extras_tags',
              'risk_score',
              'risk_score_results',
              'phase_move_check',
              'equity_check',
              'withdrawal_count',
              'rule_drawdown_type',
              'rule_first_withdrawal_after',
              'rule_inheritance',
              'rule_max_daily_drawdown',
              'rule_max_days',
              'rule_max_total_drawdown',
              'rule_min_profit',
              'rule_min_trading_days',
              'rule_special',
              'rule_withdrawal_after',
              { currency: { fields: ['label'] } },
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
              { rootParent: { fields: ['id', 'account_id', 'createdAt', { order: { fields: ['id', 'order_number', 'price'] } }] } },
              { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
            ],
          },
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'eq', visible: false,
          },
          default: this.userId,
        },
        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'multientity', queryType: 'accounts', value: 'id', label: 'account_id', search: 'account_id',
          },
        },
        {
          name: 'Deposit',
          label: 'Deposit',
          sortable: false,
        },
        {
          name: 'current_balance',
          label: 'Current balance on account',
          sortable: true,
        },
        {
          name: 'captured_balance',
          label: 'Captured balance',
          sortable: true,
        },
        {
          name: 'amount',
          label: 'Profit share $',
          sortable: true,
        },
        {
          name: 'profit_share',
          label: 'Profit share %',
          sortable: true,
        },
        {
          name: 'withdrawal_datetime',
          label: 'Withdrawal time',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: { type: 'multienum', enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'] },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'accountDeposit',
          label: 'Deposit',
          filterable: 'range',
          default: [1, null],
          visible: false,
        },
        {
          name: 'accountType',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          visible: false,
        },
      ]
    },
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    profitShareAmount(item) {
      return item.requested_amount !== null ? (item.requested_amount * (item.profit_share / 100)) : (item.amount * (item.profit_share / 100))
    },
    amount(item) {
      if (item.amount === 0) return item.account.balance - item.account.deposit

      return item.amount
    },
    getRootAccount(account, amount, profitSplit, currency) {
      if (account.rootParent !== null) {
        const createdAt = new Date(account.rootParent.createdAt)
        const dateToRefundableFee = new Date('2024-02-16T19:00:00+00:00')
        const dateTo5percBonus = new Date('2024-07-30T15:00:00+00:00')
        if (createdAt > dateTo5percBonus) return 'No refundable fee or bonus.'
        if (createdAt < dateToRefundableFee) return account.rootParent.order === null ? '+ Original order fee not found' : `+ order fee ${formatCurrency(account.rootParent.order.price, 'USD')}`

        return `+ 5% profit split. Total ${formatCurrency(amount * ((profitSplit + 5) / 100), currency)}`
      }
      return '+ Original order fee not found'
    },
  },
}
</script>
