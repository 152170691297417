<template>
  <div>
    <gql-table
      ref="orderTable"
      :query="query"
      :fields="columns"
      query-type="orders"
      sort-by="createdAt"
      :sort-desc="true"
      :title="title"
      :per-page-max="perPageMax"
    >
      <template #cell(order_number)="data">
        <b-link :to="{ name: 'order-detail-ext', params: { id: data.item.order_number } }">
          {{ data.item.order_number }}
        </b-link>
      </template>

      <template #cell(tags)="data">
        <tags-list :tags="data.item.tags" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail-ext', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(price)="data">
        {{ formatCurrency(data.item.price, 'USD') }}
        <b-badge
          v-if="data.item.discount_code"
          variant="light-primary"
        >
          {{ data.item.discount_code.code }} ({{ data.item.discount_code.discount.name }} {{ data.item.discount_code.discount.value_string }})
        </b-badge>
      </template>

      <template #cell(payment_datetime)="data">
        {{ formatDateTime(data.item.payment_datetime) }} {{ data.item.processor !== null ? `(${data.item.processor})` : '' }}
      </template>

      <template #cell(product)="data">
        <span v-if="data.item.product">
          {{ data.item.product.name }} | {{ data.item.product.platform }}
        </span>
        <span v-else>
          {{ data.item.item_description }}
        </span>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </gql-table>
  </div>
</template>

<script>
import {
  BBadge, BRow, BCol, BLink,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import TagsList from '@/components/page/TagsList.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import { ORDER_STATE_VARIANTS } from '@/enums/orders'

export default {
  components: {
    GqlTable,
    BCol,
    BRow,
    BBadge,
    BLink,
    TagsList,
    RiskScoreBadge,
  },
  props: {
    columns: Array,
    title: {
      type: String,
      required: false,
    },
    perPageMax: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      query: [
        'discount',
        'due_datetime',
        'id',
        'ip',
        'item_description',
        'order_number',
        'original_price',
        'payment_datetime',
        'price',
        'processor',
        'state',
        'createdAt',
        {
          user: {
            fields: [
              'firstName',
              'lastName',
              'id',
              'email',
              'risk_score',
              'risk_score_results',
              'kyc_at',
              'purchase_disabled',
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }],
          },
        },
        { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
        {
          product: {
            fields: [
              'name',
              'platform',
            ],
          },
        },
        {
          discount_code: {
            fields: [
              'code',
              {
                discount: {
                  fields: ['name', 'value_string'],
                },
              },
            ],
          },
        },
      ],
      stateVariants: ORDER_STATE_VARIANTS,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
  },
}
</script>
