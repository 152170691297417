<template>
  <div>
    <gql-table
      ref="accountsGqlTable"
      :fields="columns"
      :query="query"
      query-type="accounts"
      sort-by="createdAt"
      :sort-desc="true"
      :title="title"
      :per-page-max="perPageMax"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail-ext', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_subtype_name)="data">
        {{ accountSubtypes[data.item.account_type_name][data.item.account_subtype_name] }}
      </template>

      <template #cell(rules)="data">
        {{ data.item.rule_max_daily_drawdown }}%|{{ data.item.rule_max_total_drawdown }}%|{{ data.item.rule_min_trading_days }}|{{ data.item.rule_first_withdrawal_after }}|{{ data.item.rule_withdrawal_after }}|{{ data.item.profit_split }}% | {{ data.item.execution_type }} - {{ ddTypes.find(rule => rule.value === data.item.rule_drawdown_type).label }}
      </template>

      <template #cell(specialOption)="data">
        <span
          v-for="(option, key) in data.item.special_options"
          :key="key"
        > {{ option }}</span>
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="getAccountExtraTags(data.item)"
        />
      </template>

      <template #cell(account_id)="data">
        <b-link :to="{ name: 'account-detail-ext', params: { id: data.item.account_id } }">
          {{ data.item.account_id }} ({{ data.item.server.platform.id }})
        </b-link>
        <risk-score-badge
          v-if="data.item.risk_score"
          class="mx-1"
          :score-results="data.item.risk_score_results"
          :score="data.item.risk_score"
        />
      </template>

      <template #cell(parent_id)="data">
        <b-link
          v-if="data.item.parent"
          :to="{ name: 'account-detail-ext', params: { id: data.item.parent.account_id } }"
        >
          {{ data.item.parent.account_id }}
        </b-link>
      </template>

      <template #cell(order_id)="data">
        <span v-if="data.item.order">{{ data.item.order.order_number }}</span>
      </template>

      <template #cell(balance)="data">
        <b-badge
          :variant="data.item.balance>data.item.deposit ? 'success' : 'danger'"
        >
          {{ formatCurrency(data.item.balance, data.item.currency.label) }}
        </b-badge>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
      </template>
    </gql-table>
  </div>
</template>
<script>
import {
  BBadge, BCol, BLink, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import {
  ACCOUNTS_SHORT, ACCOUNT_PHASES, DRAWDOWN_TYPES_ENUM, STATE_VARIANTS,
} from '@/enums/accounts'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { getUserExtraTags } from '@/plugins/userUtils'
import RowDetail from '@/components/page/accounts/components/RowDetail.vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'

const query = [
  'id',
  'account_id',
  'account_type_name',
  'account_subtype_name',
  'balance',
  'deposit',
  'createdAt',
  'start_datetime',
  'end_datetime',
  'close_datetime',
  'state',
  'password',
  'investor_password',
  'risk_score',
  'risk_score_results',
  'execution_type',
  'phase_move_check',
  'equity_check',
  'rule_drawdown_type',
  'rule_first_withdrawal_after',
  'rule_inheritance',
  'rule_max_daily_drawdown',
  'rule_max_days',
  'rule_max_total_drawdown',
  'rule_min_profit',
  'rule_min_trading_days',
  'rule_special',
  'rule_withdrawal_after',
  'profit_split',
  'extras_tags',
  'comment',
  'special_options',
  { order: { fields: ['id', 'order_number'] } },
  { parent: { fields: ['id', 'account_id'] } },
  { rootParent: { fields: ['id', 'account_id', { order: { fields: ['id', 'order_number'] } }] } },
  { currency: { fields: ['label'] } },
  { user: { fields: ['firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
  { server: { fields: ['id', 'name', { platform: { fields: ['id'] } }] } },
]

export default {
  components: {
    ShowDetails,
    RowDetail,
    BBadge,
    TagsList,
    BRow,
    BCol,
    RiskScoreBadge,
    BLink,
    GqlTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    perPageMax: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      query,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      ddTypes: DRAWDOWN_TYPES_ENUM,
      stateVariants: STATE_VARIANTS,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getAccountExtraTags,
    getUserExtraTags,
    forceReloadAll() {
      this.$refs.accountsGqlTable.reloadAll(true)
    },
  },
}
</script>
