const ORDER_STATES = {
  paid: {
    name: 'paid',
    title: 'Paid',
    variant: 'success',
  },
  unpaid: {
    name: 'unpaid',
    title: 'Unpaid',
    variant: 'danger',
  },
  pending: {
    name: 'pending',
    title: 'Pending',
    variant: 'warning',
  },
  canceled: {
    name: 'canceled',
    title: 'Canceled',
    variant: 'info',
  },
  refund: {
    name: 'refund',
    title: 'Refund',
    variant: 'info',
  },
}

const ORDER_STATE_VARIANTS = {
  unpaid: 'danger',
  paid: 'success',
  pending: 'warning',
  canceled: 'primary',
  refund: 'info',
}

const PAYMENT_METHODS = [
  'Checkout',
  'Coinbase',
  'Crypto',
  'Payment Card',
  'PayPal',
  'Stripe',
  'Confirmo',
  'Nuvei',
]

export { ORDER_STATES, ORDER_STATE_VARIANTS, PAYMENT_METHODS }
